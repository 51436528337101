.registerContainer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  color: black;
}

@media screen and (max-width: 640px) {
  .registerContainer {
    padding-top: 4vh;
    padding-bottom: 4vh;
  }
}

.registerCard {
  padding: 1em;
  color: black;
}

.registerInputParent {
  display: flex;
}

.registerInput {
  flex-grow: 1;
  margin-bottom: 1em;
  border: solid 2px whitesmoke;
}

.registerLogo {
  margin: -1rem auto 2rem auto;
  display: block;
  width: 300px;
}

.registerAvatar {
  margin: -1rem auto 2rem auto;
  display: block;
  width: 6rem;
  height: 6rem;
  background: #0074db;
  border-radius: 50%;
  color: rgba(255, 255, 255, .85);
  font-size: .8rem;
  font-weight: 300;
  position: relative;
  vertical-align: middle;
}

.registerBtn {
  width: 65%;
  margin-bottom: 1em;
}

.toLoginBtn {
  width: 35%;
}
