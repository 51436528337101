.modalContainer {
  max-height: unset!important;
  border: solid 1px #80808036;
}

@media (min-width: 900px) {
  .modalContainer .modalBody {
    height: 60vh;
  }
}

.uploadImageParent {
  min-height: 200px;
  max-width: 300px;
  margin: auto
}

.formParent {
  min-height: 40vh;
  padding: 0.5rem 0 1.5rem 0;
}

.stepItem a {
  color: #0074db;
  text-transform: capitalize;
}

.stepItem .stepLink::before {
  border: .1rem solid #0074db;
}

.mapParent {
  height: 30vh;
}

.mapPreviewParent {
  position: relative;
  height: 300px;
}

.imgPreviewParent {
  height: auto;
  width: auto;
}

.imgPreviewParent img {
  width: 100%;
  min-height: 300px;
}

div.uploadImageParent + input.rightAlignLoader::after {
  left: 92%!important;
}

div.dayToggle {
  background-color: white;
  position: absolute;
  top: 0.3rem;
  right: 5px;
  padding-left: 0.4rem;
}

div.to[data-content]::after {
  transform: translateY(-.75rem);
  padding-left: 0;
}

.menuParent li.timeSlot {
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

li.timeSlot .addTimeButton {
  padding-left: 0;
}

span.hrsSpan:not(:last-of-type) {
  margin-right: 5px;
}

.modalFooter { z-index: 2; }


@media (min-width: 600px)  {
  .imageBody {
    margin-top: 1rem;
  }
}

@media (max-width: 600px)  {

  .modalContainer .modalFooter {
    position: fixed;
    background-color: white;
    bottom: 0px;
    width: 100%;
    padding: 0.2rem 0.8rem!important;
  }

  div.modalParent {
    padding: 0.8rem 0;
    height: 100vh;
    width: 100vw;
  }

  .autocomplete {
    margin-bottom: 0.5rem;
  }

  .mapParent {
    height: 240px;
  }

  .reverseColumnFlow {
    flex-flow: column-reverse;
  }

  button.mobileFooterBtn, a.mobileFooterBtn {
    width: 50%;
  }

  button.controlButton:last-of-type, .marginTop {
    margin-top: 0.5rem;
  }

  h4.previewHeader {
    font-size: 1rem;
  }

  .modalContainer.responsive {
    padding: 0;
    height: 100vh;
  }

  div.modalHeader.responsive {
    padding: 1rem 0;
  }

}

@media (max-width: 720px) {
  .daysParent {
    padding-bottom: 2rem;
  }
}
