 .heroLg {
  padding-top: 4rem;
  padding-bottom: 4rem;
  min-height: 100vh;
  color: black;
}
@media screen and (max-width: 640px) {
  .heroLg {
    padding-top: 4vh;
    padding-bottom: 4vh;
  }
}

.login {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.card {
  padding: 1em;
}

.card .card-body:last-child {
  padding-bottom: 0;
}

.loginLabel {
  color: black;
}

.loginInputParent {
  display: flex;
}

.loginInput {
  flex-grow: 1;
  margin-bottom: 1em;
  border: solid 2px whitesmoke;
}

.loginInput:last-of-type {
  margin-bottom: 2em;
}

.loginLogo {
  width: 300px;
  display: block;
  margin: -1rem auto 2rem auto;
}

.loginBtn {
  width: 65%;
}

.toRegisterBtn {
  width: 35%;
}
