div.spacesParent {
  height: calc(100vh - 120px);
  padding-top: 1px;
}

div.spacesListParent {
  padding: 1rem;
  max-height: 100%;
  overflow-y: scroll;
}

.statusParent:not(:last-child) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.statusParent br + span {
  margin-top: 0.5rem;
  display: block;
}

.booking {
  margin-top: 2rem;
}

.tile {
  border: solid 1px lightgrey;
  border-radius: 2px;
}

.tileContent {
  padding-top: 6px;
  padding-bottom: 6px;
}

.tileAction {
  padding-top: 6px;
  padding-right: 6px;
}

.booking .imageParent {
  max-width: 150px;
}

.contactLabel {
  white-space: nowrap;
}

.extendForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

span.username {
  text-decoration: underline;
  font-weight: bolder;
}

span.username:hover {
  cursor: pointer;
}

@media (max-width: 740px) {
  .tile {
    flex-direction: column;
  }
  .imageParent {
    margin: auto;
  }
}
