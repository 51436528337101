.logoParent {
  text-align: center;
  width: 33%;
}

.logo {
  max-width: 100%;
}

.tagline {
  float: right;
  color: #f5f5f5c7;
  font-size: 1rem;
}

.byline {
  margin: 3rem;
}

.iconParent {
  padding: 2.5rem 0;
}

.iconParent:hover {
  cursor: pointer;
}

.modalContainer {
  max-height: unset!important;
}

.icon {
  max-width: 100px;
}

.welcomeModal {
  z-index: 2000;
}
