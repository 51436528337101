.landingTitle {
  word-break: inherit;
}

.role { margin-left: 6px; }
.role input[type="radio"] {
  vertical-align: middle;
  margin-right: 6px;
}

.yesBtn {
  width: 100%;
  margin-top: 1rem;
}
