.mapMarker {
  position: relative;
  width: 24px;
  top: -24px;
  left: -12px;
  height: 16px;
  padding-top: 0px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1px;
  background: #0074db;
  color: white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.mapMarker:hover { cursor: pointer; }

.mapMarker:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 8px 4px 0;
  border-color: #0074db transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -8px;
  left: 8px;
}

.mapMarkerWhiteBorder {
  position: relative;
  width: 250px;
  height: 120px;
  padding: 0px;
  background: #0074db;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: #fff solid 3px;
}

.mapMarkerWhiteBorder:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 45px 19px 0;
  border-color: #0074db transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -45px;
  left: 106px;
}

.mapMarkerWhiteBorder:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 47px 21px 0;
  border-color: #fff transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -50px;
  left: 104px;
}
